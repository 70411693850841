import { callApi } from 'utils/httpClient';
import { EquisoftDocument } from '../schemas/equisoftDocumentSchema';
import { FormatDoc } from '../component/EquisoftFormDocument';

const gcServiceUrl = `${process.env.REACT_APP_EQUISOFT_SERVICE_URL}`;

async function createEquisoftDocument(equisoftDocuments: EquisoftDocument[]) {
  
  const formData = new FormData();

  //Remove the object completety in Multiple doc to not create empty Doc in Equisoft
  if(equisoftDocuments.length > 0 && equisoftDocuments[0].formatDoc === FormatDoc.MultipleDoc) {
    equisoftDocuments = equisoftDocuments.filter((doc) => doc.files[0].synchronize === true);
  }

  formData.append('data', JSON.stringify(equisoftDocuments));
  for (let i = 0; i < equisoftDocuments.length; i++) {
    for (let j = 0; j < equisoftDocuments[i].files.length; j++) {
      if(equisoftDocuments[i].files[j].synchronize === true) {
        formData.append(`equisoftDocument${i}files${j}`, equisoftDocuments[i].files[j].file as File);
      }
    }
  }

  const { data } = await callApi('post', gcServiceUrl, '/equisoft/document', formData, 'multipart/form-data');
  return data;
}
export default createEquisoftDocument;