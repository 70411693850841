import { Namespace, TFunction } from 'react-i18next';
import { TransactionBusinessEnum, TransactionCategoryEnum } from 'types/transaction';
import { FinancialCenter } from 'types/FinancialCenter';

export function getSelectInformation(t: TFunction<Namespace>) {
  const businessOptions = [
    {
      value: TransactionBusinessEnum.LIFE_INSURANCE,
      label: t('lifeInsurance', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.LIVING_BENEFITS,
      label: t('livingBenefits', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.PROTEK_SOLUTIONS,
      label: t('protekSolutions', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.MORTGAGE,
      label: t('mortgage', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.INVESTMENTS,
      label: t('investments', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.GROUP_INSURANCE,
      label: t('groupInsurance', { ns: 'genericItem' }),
    },
    {
      value: TransactionBusinessEnum.OTHERS,
      label: t('others', { ns: 'genericItem' }),
    },
  ];

  const categoryOptionsForInsuranceAndCollectiveAnnuities = [
    {
      value: TransactionCategoryEnum.SUBMISSION,
      label: t('submission', { ns: 'common' }),
    },
    {
      value: TransactionCategoryEnum.SERVICES,
      label: t('services', { ns: 'common' }),
    },
    {
      value: TransactionCategoryEnum.RENEWAL,
      label: t('renewal', { ns: 'common' }),
    },
    {
      value: TransactionCategoryEnum.AGIL_TPA,
      label: t('agilTpa', { ns: 'common' }),
    },
  ];

  const categoryOptionsForSellingTeams = [
    {
      value: TransactionCategoryEnum.SELLING_TEAMS,
      label: t('sellingTeams', { ns: 'common' }),
    }
  ];

  const categoryOptionsForTransaction = [
    {
      value: TransactionCategoryEnum.SELLING_TEAMS,
      label: t('sellingTeams', { ns: 'common' }),
    },
    {
      value: TransactionCategoryEnum.TRANSACTION,
      label: t('transaction', { ns: 'common' }),
    }
  ];

  const categoryOptionsForOthers = [
    {
      value: TransactionCategoryEnum.GENERAL,
      label: t('general', { ns: 'common' }),
    }
  ];

  return { businessOptions, categoryOptionsForInsuranceAndCollectiveAnnuities, categoryOptionsForSellingTeams, categoryOptionsForTransaction, categoryOptionsForOthers };
}

export function getFinancialCenters(t: TFunction<Namespace>) {
  const financialCenters = [
    {
      value: FinancialCenter.MONTREAL,
      label: t('montreal', { ns: 'financialCenter' }),
    },
    {
      value: FinancialCenter.QUEBEC,
      label: t('quebec', { ns: 'financialCenter' }),
    },
    {
      value: FinancialCenter.ST_HYACINTHE,
      label: t('stHyacinthe', { ns: 'financialCenter' }),
    },
    {
      value: FinancialCenter.ST_HYACINTHE,
      label: t('outaouais', { ns: 'financialCenter' }),
    },
    {
      value: FinancialCenter.SHERBROOKE,
      label: t('sherbrooke', { ns: 'financialCenter' }),
    },
    {
      value: FinancialCenter.TROIS_RIVIERES,
      label: t('troisRivieres', { ns: 'financialCenter' }),
    },
  ];

  return { financialCenters };
}

export function getTransactionTypes(t: TFunction<Namespace>) {
  const availableTransactionTypes = [
    {
      value: '858020000',
      label: t('858020000', { ns: 'crmTransaction' }),
    },
    {
      value: '858020001',
      label: t('858020001', { ns: 'crmTransaction' }),
    },
    {
      value: '858020002',
      label: t('858020002', { ns: 'crmTransaction' }),
    },
  ];

  return { availableTransactionTypes };
}

export function getDemandTypes(t: TFunction<Namespace>) {
  const demandTypes = [
    {
      value: '858020000',
      label: t('858020000', { ns: 'crmDemand' }),
    },
    {
      value: '858020000',
      label: t('858020000-1', { ns: 'crmDemand' }),
    },
    {
      value: '858020002',
      label: t('858020002', { ns: 'crmDemand' }),
    },
    {
      value: '858020003',
      label: t('858020003', { ns: 'crmDemand' }),
    },
    {
      value: '858020004',
      label: t('858020004', { ns: 'crmDemand' }),
    },
    {
      value: '858020012',
      label: t('858020012', { ns: 'crmDemand' }),
    },
    {
      value: '858020007',
      label: t('858020007', { ns: 'crmDemand' }),
    },
    {
      value: '858020013',
      label: t('858020013', { ns: 'crmDemand' }),
    },
    {
      value: '858020005',
      label: t('858020005', { ns: 'crmDemand' }),
    },
    {
      value: '858020006',
      label: t('858020006', { ns: 'crmDemand' }),
    },
    {
      value: '858020015',
      label: t('858020015', { ns: 'crmDemand' }),
    },
    {
      value: '858020016',
      label: t('858020016', { ns: 'crmDemand' }),
    },
    {
      value: '858020008',
      label: t('858020008', { ns: 'crmDemand' }),
    },
    {
      value: '858020010',
      label: t('858020010', { ns: 'crmDemand' }),
    },
    {
      value: '858020009',
      label: t('858020009', { ns: 'crmDemand' }),
    },
  ];

  return { demandTypes };
}
